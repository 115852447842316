<script setup>
import { router } from "@inertiajs/vue3";
import { AutoComplete } from "primevue";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import InputText from "primevue/inputtext";
import { ref } from "vue";

const searchQuery = ref("");
const filteredResults = ref([]);

const searchClicked = (event) => {
    searchQuery.value = "";
    router.visit(event.value.link);
};

const searchResults = async (event) => {
    await axios
        .post(route("read_only.search-all"), { search: event.query })
        .then(function (response) {
            if (response.data.length > 0) {
                filteredResults.value = response.data;
            } else {
                filteredResults.value = [
                    {
                        link: null,
                        title: "No Results Found",
                        subText: null,
                    },
                ];
            }
        });
};
</script>

<template>
    <IconField>
        <AutoComplete
            v-model="searchQuery"
            :suggestions="filteredResults"
            :min-length="3"
            field="name"
            placeholder="Search Patients..."
            scroll-height="350px"
            @item-select="searchClicked($event)"
            @complete="searchResults($event)"
        >
            <template #option="slotProps">
                <!--                    <img-->
                <!--                        class="h-9 w-9 rounded-full object-cover p-0.5"-->
                <!--                        :src="defaultProfilePhotoUrl(slotProps.item.title)"-->
                <!--                    />-->
                <div class="ml-2">
                    <p class="text-sm font-semibold leading-none text-primary">
                        {{ slotProps.option.title }}
                    </p>
                    <p class="text-xs font-normal text-tertiary">
                        {{ slotProps.option.subtext }}
                    </p>
                </div>
            </template>
        </AutoComplete>
        <InputIcon class="pi pi-search" />
    </IconField>
</template>
